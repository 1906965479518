"use client";
import "react-lazy-load-image-component/src/effects/blur.css";
import Link from "next/link";
import FavoriteIcon from "@/components/UI/FavoriteIcon";
import { useRouter } from "next/navigation";
import { motion } from "framer-motion";
import { useState } from "react";
import useLocalStorage from "@/components/Hooks/UseLocalStorage";
import classes from "./CarouselListing.module.css";
import { useAuthContext } from "@/context";

const CarouselListing = (props) => {
  const { listing, idx } = props;

  const [isHovering, setIsHovering] = useState(false);

  const router = useRouter();

  const { auth } = useAuthContext();

  const [recentlyViewed, setRecentlyViewed] = useLocalStorage(
    "recentlyViewed",
    []
  );

  const brandHandleClicker = (brand) => {
    router.push(
      `/brand/${brand.toLowerCase().replace(/ /g, "-").replace(/&/g, "and")}`
    );
  };

  const onClickButton = (list) => {
    const updatedList = [];
    const updatedRecentlyViewed = recentlyViewed
      .filter((o) => o._id !== list?._id)
      .slice(-15);
    // Add the new object to the updated array

    updatedList.push(...updatedRecentlyViewed, {
      _id: list._id,
      item_category: list.newSchema.categories[0],
      item_category2: list.newSchema.subCategories[0]
        ? list.newSchema.subCategories[0]
        : "",
      item_category3: list.newSchema.types[0] ? list.newSchema.types[0] : "",
      external_seller: list.author ? list.author : "",
      price: list.price ? list.price : "",
      title: list.title ? list.title : "",
      brand: list.brand
        ? list.brand
        : list.velouAttributes?.manufacturer?.length > 0
        ? list.velouAttributes?.manufacturer[0]
            ?.replace(/_/g, " ")
            ?.split(" ")
            ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            ?.join(" ")
        : list.velouAttributes?.brands?.length > 0
        ? list.velouAttributes?.brands[0]
            ?.replace(/_/g, " ")
            ?.split(" ")
            ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            ?.join(" ")
        : "",
      offerEmail: list.price * 0.9,
      img: list.images[0] ? list.images[0] : "",
    });

    setRecentlyViewed(updatedList);

    process.env.NEXT_PUBLIC_NODE_ENV === "production" &&
      global?.window &&
      window.analytics?.track("view_item", {
        queryID: localStorage.getItem("queryID")
          ? localStorage.getItem("queryID")
          : "",
        currency: "USD",
        queryID: localStorage.getItem("queryID")
          ? localStorage.getItem("queryID")
          : "",
        position: idx ? idx + 1 : 0,
        value: (list.price * 0.2).toFixed(0),
        external_seller: list.author ? list.author : "",
        index: "kashew_listings",
        eventType: "click",
        objectID: list._id,
        items: [
          {
            item_id: list._id,
            item_name: list.title,
            currency: "USD",
            item_brand: list.brand
              ? list.brand
              : list.velouAttributes?.manufacturer?.length > 0
              ? list.velouAttributes?.manufacturer[0]
                  ?.replace(/_/g, " ")
                  ?.split(" ")
                  ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  ?.join(" ")
              : list.velouAttributes?.brands?.length > 0
              ? list.velouAttributes?.brands[0]
                  ?.replace(/_/g, " ")
                  ?.split(" ")
                  ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  ?.join(" ")
              : "",
            item_category: list.newSchema.categories[0],
            item_category2: list.newSchema.subCategories[0]
              ? list.newSchema.subCategories[0]
              : "",
            item_category3: list.newSchema.types[0]
              ? list.newSchema.types[0]
              : "",
            price: list.price.toFixed(0),
          },
        ],
      });
    if (!auth.loading && auth?.isAuthenticated) {
      process.env.NEXT_PUBLIC_NODE_ENV === "production" &&
        global?.window &&
        window.analytics?.identify(auth.user._id, {
          email: auth.user.email,

          recentlyViewedItems: JSON.stringify(
            updatedList
              .filter((o) => o.external_seller === "622651a7a84d1d6c836497c3")
              .slice(-2)
          ),
        });
    }
  };
  if (!listing) {
    return <div></div>;
  }

  const displayTitle = listing.title;

  return (
    <div className={`${classes.listingCard}`} key={listing._id}>
      <Link
        onClick={() => onClickButton(listing)}
        href={`/product/${listing.kashewId}/${listing?.title
          ?.toLowerCase()
          .replace(/[_\W]+/g, "-")
          .replace(/^-+/, "")
          .replace(/-+$/, "")}`}
        className={classes.Link}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className={classes.carousel}>
          <img
            className={classes.listingImg1}
            src={`${listing.images[0]}?tr=w-500`}
            alt={listing.title}
          />

          {listing.estRetailPrice && listing.estRetailPrice > listing.price && (
            <div className={classes.dealBadge}>
              -
              {`${(
                ((listing.price - listing.estRetailPrice) /
                  listing.estRetailPrice) *
                -100
              ).toFixed(0)}`}
              %
            </div>
          )}

          <motion.div
            className={classes.favorite}
            initial={isHovering ? { opacity: 1 } : { opacity: 0 }}
            animate={isHovering ? { opacity: 1 } : { opacity: 0 }}
            transition={{
              duration: 0.4,
            }}
          >
            <FavoriteIcon listing={listing} />
          </motion.div>
          {listing.viewed > 5 && (
            <div className={classes.viewedXTimes}>
              {" "}
              <img
                src="https://ik.imagekit.io/kuj9zqlrx0or/Static/eye.png"
                className={classes.viewedIcon}
                alt={"popular item used furniture - viewed multiple times"}
              />{" "}
              {listing.viewed}
            </div>
          )}
          {listing.brand ? (
            <div
              className={classes.brand}
              onClick={() => brandHandleClicker(listing.brand)}
            >
              {listing.brand?.slice(0, 50)}
            </div>
          ) : listing.velouAttributes?.manufacturer?.length > 0 ? (
            <div
              className={classes.brand}
              onClick={() =>
                brandHandleClicker(
                  listing.velouAttributes?.manufacturer[0]
                    ?.replace(/_/g, " ")
                    ?.split(" ")
                    ?.map(
                      (word) => word.charAt(0).toUpperCase() + word.slice(1)
                    )
                    ?.join(" ")
                )
              }
            >
              {listing.velouAttributes?.manufacturer[0]
                ?.replace(/_/g, " ")
                ?.split(" ")
                ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                ?.join(" ")
                .slice(0, 50)}
            </div>
          ) : listing.velouAttributes?.brands?.length > 0 ? (
            <div
              className={classes.brand}
              onClick={() =>
                brandHandleClicker(
                  listing.velouAttributes?.brands[0]
                    ?.replace(/_/g, " ")
                    ?.split(" ")
                    ?.map(
                      (word) => word.charAt(0).toUpperCase() + word.slice(1)
                    )
                    ?.join(" ")
                )
              }
            >
              {listing.velouAttributes?.brands[0]
                ?.replace(/_/g, " ")
                ?.split(" ")
                ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                ?.join(" ")
                ?.slice(0, 50)}
            </div>
          ) : (
            ""
          )}
          {listing.status === "sold" && <p className={classes.sold}>SOLD</p>}
        </div>
        <div className={classes.cardBody}>
          <div className={classes.titleDiv}>
            <h3 className={classes.title}> {displayTitle} </h3>
          </div>

          <div className={classes.infoSection}>
            {listing.estRetailPrice &&
            listing.estRetailPrice > listing.price ? (
              <p className={`${classes.priceRed} `}>
                ${listing.price.toLocaleString()}
              </p>
            ) : (
              <p className={`${classes.price} `}>
                ${listing.price.toLocaleString()}
              </p>
            )}
            {listing.estRetailPrice &&
              listing.estRetailPrice > listing.price && (
                <p className={`${classes.retailPrice} `}>
                  ${listing.estRetailPrice.toLocaleString()}
                </p>
              )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CarouselListing;
