'use client';
import { useMemo, useState } from 'react';
import Link from 'next/link';
import MultiCarousel from './MultiCarousel';
import PlaceHolder from './PlaceHolder';
import classes from './Universal.module.css';
import algoliasearch from 'algoliasearch';
const client =
	process.env.NEXT_PUBLIC_OG_DB === 'true'
		? algoliasearch(
				process.env.NEXT_PUBLIC_ALGOLIA_APPID,
				process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
		  )
		: algoliasearch(
				process.env.NEXT_PUBLIC_DUMMY_ALGOLIA_APPID,
				process.env.NEXT_PUBLIC_DUMMY_ALGOLIA_API_KEY
		  );
const UniversalCarousel = (props) => {
	const { header, link, categories, style, sort } = props;
	const [displayListings, setDisplayListings] = useState([]);
	const [loading, setLoading] = useState(false);

	const getListingsHandler = async (categories, style, sort) => {
		setLoading(true);

		const index = client.initIndex(
			process.env.NEXT_PUBLIC_OG_DB === 'false'
				? 'kashew_listings'
				: sort?.relevace == -1
				? `kashew_listings`
				: sort?.date == -1
				? 'kashew_listings_date_desc'
				: 'kashew_listings'
		);
		await index
			.search('', {
				hitsPerPage: 10,
				clickAnalytics: true,
				page: 0,
				filters: `status:active${
					categories?.length > 0
						? ` AND (${categories.map((category, idx) =>
								idx === 0
									? `newSchema.categories:"${category}" OR newSchema.subCategories:"${category}" OR newSchema.types:"${category}"`
									: ` OR newSchema.categories:"${category}" OR newSchema.subCategories:"${category}" OR newSchema.types:"${category}"`
						  )})`
						: ''
				}${
					style
						? ` AND velouAttributes.styles:"${style[0]
								.replace(/-/g, ' ')
								.replace(/\band\b/g, '&')
								.replace(' ', '_')}"`
						: ''
				}`.replace(/,/g, ''),
			})
			.then(async (res) => {
				setDisplayListings(res.hits);
			})
			.catch((err) => {
				console.log(err);
			});
		setLoading(false);
	};

	useMemo(() => {
		if (categories.length > 0) {
			getListingsHandler(categories, style, sort);
		}
	}, []);

	return (
		<>
			{(loading || displayListings.length > 0) && (
				<div className={classes.main}>
					<div className={classes.headerBox}>
						<h3 className={classes.header}>{header}</h3>
						<Link className={classes.Link} href={link}>
							<h6 className={classes.seeAll}>See all</h6>
						</Link>
					</div>
					{displayListings.length > 0 && !loading ? (
						<MultiCarousel listings={displayListings} />
					) : (
						<PlaceHolder />
					)}
				</div>
			)}
		</>
	);
};

export default UniversalCarousel;
