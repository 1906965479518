'use client';
import Script from 'next/script'; // Import for dynamic script handling
import { useState, useEffect } from 'react';

const Scripts = () => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		// Defer loading of Google Analytics
		setTimeout(() => {
			setShow(true);
		}, 2000);
	}, []);

	return (
		<>
			{show && (
				<>
					{/* Google Analytics */}
					<Script
						src="https://www.googletagmanager.com/gtag/js?id=G-4ETNV1WVWL"
						strategy="afterInteractive"
					/>
					<Script id="google-analytics" strategy="afterInteractive">
						{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-4ETNV1WVWL');
            `}
					</Script>

					{/* Segment Analytics */}
					<Script id="segment-analytics" strategy="afterInteractive">
						{`
			!function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="zMghWQu5eOKdyJl774DFQHiKTkwyD0GH";;analytics.SNIPPET_VERSION="5.2.0";
			analytics.load("zMghWQu5eOKdyJl774DFQHiKTkwyD0GH");
			analytics.page();
			}}();
        `}
					</Script>

					{/* Hotjar */}
					<Script id="hotjar-embed">
						{`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3383562,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
					</Script>
				</>
			)}
		</>
	);
};

export default Scripts;
