'use client';
import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { IoCloseOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';
import classes from './AuthUser.module.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useAlertContext } from '@/context/alert';
import { useAuthContext } from '@/context';
import CustomModal from '@/components/UI/CustomModal';
import FacebookLoginWrapper from '@/components/Auth/FacebookLoginWrapper';

const AuthUser = (props1) => {
	const {
		REGISTER_FAIL,
		LOAD_USER,
		LOGIN_SUCCESS,
		LOGIN_FAIL,
		REGISTER_SUCCESS,
		auth,
	} = useAuthContext();

	const {
		from,
		open,
		setRequestAuth,
		setViewingModal,
		isCheckout,
		setGuestEmail,
		guestEmail,
		guestName,
		setGuestName,
		setIsGuest,
		showAddPayment,
		setShowAddPayment,
		startOnboardingHandler,
		isInteriorDesigner,
		submitInteriorHandler,
	} = props1;

	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(guestEmail ? 'guest' : 'register');
	const [addPayment] = useState(showAddPayment);
	const [error, setError] = useState(null);

	const { SET_ALERT } = useAlertContext();

	const ref = useRef();

	const [formData, setFormData] = useState({
		email: '',
		password: '',
	});

	const { email, password } = formData;

	const [cart, setCart] = useState();

	useEffect(() => {
		setCart(
			localStorage.cart && Array.isArray(JSON.parse(localStorage.cart))
				? [...JSON.parse(localStorage.cart)]
				: []
		);
	}, []);

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (open && ref.current && !ref.current.contains(e.target)) {
				setRequestAuth(false);
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [open]);

	useEffect(() => {
		if (auth.isAuthenticated) {
			setRequestAuth(false);
		}
		if (startOnboardingHandler) {
			startOnboardingHandler();
		}
	}, [auth]);

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const loginHandler = async (props) => {
		setError(null);
		const { userEmail, name, picture, fbId, glId } = props;
		setLoading(true);

		await axios
			.post(`${process.env.NEXT_PUBLIC_SERVER_API}/api/auth`, {
				email: userEmail ? userEmail : email,
				password,
				name,
				guestUserId:
					localStorage.getItem('guestUser') &&
					localStorage.getItem('guestUser') !== 'undefined' &&
					localStorage.getItem('guestUser') !== 'null'
						? localStorage.getItem('guestUser')
						: '',
				source:
					localStorage.getItem('source') &&
					localStorage.getItem('source') !== 'undefined' &&
					localStorage.getItem('source') !== 'null'
						? localStorage.getItem('source')
						: 'NA',

				picture,
				glId,
				fbId,
				cart: cart ? cart : [],
			})
			//if succesful:
			.then(async (res) => {
				localStorage.removeItem('source');
				localStorage.removeItem('guestUser');

				SET_ALERT({
					msg: 'Logged in!',
					type: 'success',
				});

				LOGIN_SUCCESS(res.data);
				if (isInteriorDesigner) {
					submitInteriorHandler(true);
				}
				LOAD_USER();

				if (setViewingModal) {
					setViewingModal(true);
				}
			})
			//login fail
			.catch((error) => {
				console.log(error);
				setError(
					error.response?.data?.errors?.length > 0
						? error.response.data.errors[0].msg
						: 'Server error'
				);
				LOGIN_FAIL();
			});
		setLoading(false);
	};

	const submitHandler = async (event) => {
		event.preventDefault();
		setLoading(true);

		loginHandler({
			userEmail: null,
			name: null,
			picture: null,
			fbId: null,
			glId: null,
		});
	};

	const responseFacebook = async (response) => {
		if (response.accessToken) {
			loginHandler({
				userEmail: response.email,
				name: response.name,
				picture: response.picture
					? response.picture.data.url
						? response.picture.data.url
						: null
					: null,
				fbId: response.id,
				glId: null,
			});
		}
	};

	const responseGoogle = async (response) => {
		if (response.tokenId) {
			loginHandler({
				userEmail: response.profileObj.email,
				name: response.profileObj.givenName
					? response.profileObj.givenName
					: response.profileObj.name,
				picture: response.profileObj.imageUrl
					? response.profileObj.imageUrl
					: null,
				glId: response.profileObj.googleId,
				fbId: null,
			});
		}
	};

	//REGISTER LOGIC
	const [passwordShown, setPasswordShown] = useState(false);

	const [formDataRegister, setFormDataRegister] = useState({
		emailRegister: '',
		passwordRegister: '',
		firstName: '',
	});

	useEffect(() => {
		setFormDataRegister({
			emailRegister: localStorage.getItem('email')
				? localStorage.getItem('email')
				: '',
			passwordRegister: '',
			firstName: localStorage.getItem('firstName')
				? localStorage.getItem('firstName')
				: '',
		});
	}, []);
	const { emailRegister, passwordRegister, firstName } = formDataRegister;

	const [usrAgreementAccepted, setUsrAgreementAccepted] = useState(true);

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const registerHandler = async (props) => {
		const { userEmail, name, picture, fbId, glId } = props;

		let validEmail = validateEmail(userEmail ? userEmail : emailRegister);
		if (!validEmail) {
			return setError('Enter a valid email address');
		}

		setLoading(true);

		await axios
			.post(`${process.env.NEXT_PUBLIC_SERVER_API}/api/users`, {
				email: userEmail ? userEmail : emailRegister,
				password: passwordRegister,
				firstName: name ? name : firstName,
				guestUserId:
					localStorage.getItem('guestUser') &&
					localStorage.getItem('guestUser') !== 'undefined' &&
					localStorage.getItem('guestUser') !== 'null'
						? localStorage.getItem('guestUser')
						: '',
				source:
					localStorage.getItem('source') &&
					localStorage.getItem('source') !== 'undefined' &&
					localStorage.getItem('source') !== 'null'
						? localStorage.getItem('source')
						: 'NA',
				picture,
				glId,
				fbId,
				cart: cart ? cart : [],
			})
			.then((res) => {
				localStorage.removeItem('source');
				localStorage.removeItem('guestUser');
				//if succesful:

				SET_ALERT({
					msg: 'You have been succesfully registered!',
					type: 'success',
				});

				REGISTER_SUCCESS(res.data);
				if (isInteriorDesigner) {
					submitInteriorHandler(true);
				}
				LOAD_USER();

				if (setViewingModal) {
					setViewingModal(true);
				}
			})
			.catch((error) => {
				console.log(error);

				setError(
					error.response?.data?.errors?.length > 0
						? error.response.data.errors[0].msg
						: 'Server error'
				);

				REGISTER_FAIL();
			});
		setLoading(false);
	};

	const submitHandlerRegister = async (event) => {
		//prevent reload
		event.preventDefault();

		setError(null);

		//check if passwords match
		if (!usrAgreementAccepted) {
			setError('You need to accept the user agreement');
		} else {
			registerHandler({
				userEmail: null,
				name: null,
				picture: null,
				fbId: null,
				glId: null,
			});
		}
	};

	const onChangeRegister = (e) => {
		setFormDataRegister({
			...formDataRegister,
			[e.target.name]: e.target.value,
		});
	};

	const usrAgreementHandler = () => {
		setUsrAgreementAccepted(!usrAgreementAccepted);
	};

	const label = (
		<span className={classes.smallText}>
			I agree to the{' '}
			<a href="/Terms-of-use" target="_blank" className={classes.anchor}>
				terms of use
			</a>
		</span>
	);

	const responseFacebookRegister = async (response) => {
		if (response.accessToken) {
			registerHandler({
				userEmail: response.email,
				name: response.name,
				picture: response.picture
					? response.picture.data.url
						? response.picture.data.url
						: null
					: null,
				fbId: response.id,
				glId: null,
			});
		}
	};

	const responseGoogleRegister = async (response) => {
		if (response.tokenId) {
			registerHandler({
				userEmail: response.profileObj.email,
				name: response.profileObj.givenName
					? response.profileObj.givenName
					: response.profileObj.name,
				picture: response.profileObj.imageUrl
					? response.profileObj.imageUrl
					: null,
				glId: response.profileObj.googleId,
				fbId: null,
			});
		}
	};

	const submitHandlerGuest = (e) => {
		e?.preventDefault();
		setIsGuest(true);
		if (addPayment) setShowAddPayment(true);
		setRequestAuth(false);
	};

	if (!open) return null;
	return (
		<CustomModal>
			{/* <div className={classes.overlay} /> */}

			<motion.div
				ref={ref}
				initial={{ y: '100vh' }}
				animate={{ y: 0 }}
				exit={{ y: '100vh' }}
				transition={{
					duration: 0.3,
				}}
				className={classes.modal}
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<div className={classes.info}>
					<IoCloseOutline
						className={classes.closeIcon}
						onClick={() => setRequestAuth(false)}
					/>
					{isCheckout ? (
						<h2 className={classes.titleText}>Choose how to continue</h2>
					) : page === 'login' ? (
						<>
							{from === 'favorites' ? (
								<h2 className={classes.titleText}>
									Log in to save this listing and be notified when the price
									drops
								</h2>
							) : (
								<h2 className={classes.titleText}>Log in to continue</h2>
							)}
						</>
					) : (
						<>
							{from === 'favorites' ? (
								<h2 className={classes.titleText}>
									Register to save this listing and be notified when the price
									drops
								</h2>
							) : (
								<h2 className={classes.titleText}>Register in to continue</h2>
							)}
						</>
					)}
				</div>
				<div className={classes.maxWidth}>
					<div className={classes.tabs}>
						{isCheckout && (
							<button
								onClick={() => setPage('guest')}
								className={page === 'guest' ? classes.tabSelected : classes.tab}
							>
								Guest checkout
							</button>
						)}
						<button
							onClick={() => setPage('register')}
							className={
								page === 'register' ? classes.tabSelected : classes.tab
							}
						>
							Register
						</button>
						<button
							onClick={() => setPage('login')}
							className={page === 'login' ? classes.tabSelected : classes.tab}
						>
							Log in
						</button>
					</div>

					{page === 'login' && (
						<>
							<form className={classes.form} onSubmit={submitHandler}>
								{isCheckout && (
									<div className={classes.infoText}>
										Log in to use promo codes
									</div>
								)}
								<div className={classes.buttons}>
									<GoogleLogin
										clientId="766168399417-jq3ifugiaqecrimfbkql8qkkcpmvrqcg.apps.googleusercontent.com"
										buttonText="WITH GOOGLE"
										onSuccess={responseGoogle}
										prompt="select_account"
										onFailure={responseGoogle}
										cookiePolicy={'single_host_origin'}
										className={classes.googleButton}
									/>

									<FacebookLoginWrapper
										facebookResponseCallBack={responseFacebook}
									/>
								</div>
								<div className={classes.separator}>
									<div className={classes.line} />
									<div className={classes.separatorText}>or</div>
									<div className={classes.line} />
								</div>
								<div className={classes.logInWithEmail}>
									<Form.Group controlId="Email">
										<Form.Control
											className={classes.formControl}
											type="email"
											placeholder="email"
											name="email"
											onChange={(e) => onChange(e)}
											required
										/>
									</Form.Group>

									<Form.Group controlId="Password">
										<Form.Control
											className={classes.formControl}
											type="password"
											name="password"
											placeholder="password"
											onChange={(e) => onChange(e)}
											required
										/>
									</Form.Group>

									<span className={classes.helpLinks}>
										<Link className={classes.Link} href={'/reset'}>
											Forgot your password? <br />
										</Link>
									</span>
								</div>

								{error && (
									<Form.Group controlId="formBasicCheckbox">
										<div className={classes.error}>{error}</div>
									</Form.Group>
								)}

								<button type="submit" className={classes.button}>
									{loading ? (
										<svg
											width="20px"
											height="20px"
											viewBox="0 0 135 135"
											xmlns="http://www.w3.org/2000/svg"
											fill="#fff"
										>
											<path d="M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z">
												<animateTransform
													attributeName="transform"
													type="rotate"
													from="0 67 67"
													to="-360 67 67"
													dur="2.5s"
													repeatCount="indefinite"
												/>
											</path>
											<path d="M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z">
												<animateTransform
													attributeName="transform"
													type="rotate"
													from="0 67 67"
													to="360 67 67"
													dur="8s"
													repeatCount="indefinite"
												/>
											</path>
										</svg>
									) : (
										`Login`
									)}
								</button>

								<span
									onClick={() => setPage('register')}
									className={classes.helpLinks}
								>
									Don't have an account yet?{' '}
									<div className={classes.anchor}>Register</div>
								</span>
							</form>
						</>
					)}

					{page === 'register' && (
						<>
							<form onSubmit={submitHandlerRegister} className={classes.form}>
								{isCheckout && (
									<div className={classes.infoText}>
										Sign up to use promo codes
									</div>
								)}
								<div className={classes.buttons}>
									<GoogleLogin
										clientId="766168399417-jq3ifugiaqecrimfbkql8qkkcpmvrqcg.apps.googleusercontent.com"
										buttonText="WITH GOOGLE"
										prompt="select_account"
										onSuccess={responseGoogleRegister}
										onFailure={responseGoogleRegister}
										cookiePolicy={'single_host_origin'}
										className={classes.googleButton}
									/>

									<FacebookLoginWrapper
										facebookResponseCallBack={responseFacebook}
									/>
								</div>

								<div className={classes.separator}>
									<div className={classes.line} />
									<div className={classes.separatorText}>or</div>
									<div className={classes.line} />
								</div>
								<div className={classes.logInWithEmail}>
									<Form.Group controlId="Re-Password">
										<Form.Control
											className={classes.formControl}
											type="text"
											value={formDataRegister.firstName}
											name="firstName"
											placeholder="name"
											onChange={(e) => onChangeRegister(e)}
											required
										/>
									</Form.Group>
									<Form.Group controlId="Email">
										<Form.Control
											className={classes.formControl}
											type="email"
											value={formDataRegister.email}
											placeholder="email"
											name="emailRegister"
											onChange={(e) => onChangeRegister(e)}
											required
										/>
									</Form.Group>

									<Form.Group controlId="Password">
										<InputGroup>
											<div className={classes.inputContainer}>
												<Form.Control
													className={classes.formControl}
													type={passwordShown ? 'text' : 'password'}
													name="passwordRegister"
													placeholder="password"
													onChange={(e) => onChangeRegister(e)}
													required
												/>
												{passwordShown ? (
													<AiOutlineEye
														onClick={togglePassword}
														className={classes.lense}
													/>
												) : (
													<AiOutlineEyeInvisible
														onClick={togglePassword}
														className={classes.lense}
													/>
												)}
											</div>
										</InputGroup>
									</Form.Group>

									<Form.Group controlId="formBasicCheckbox">
										<Form.Check
											type="checkbox"
											label={label}
											checked={usrAgreementAccepted}
											onClick={usrAgreementHandler}
											id="userAgreement"
										/>
									</Form.Group>

									{/* <div className={classes.separator} /> */}

									{error && (
										<Form.Group controlId="formBasicCheckbox">
											<div className={classes.error}>{error}</div>
										</Form.Group>
									)}
								</div>

								<button className={classes.button} type="submit">
									{loading ? (
										<svg
											width="20px"
											height="20px"
											viewBox="0 0 135 135"
											xmlns="http://www.w3.org/2000/svg"
											fill="#fff"
										>
											<path d="M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z">
												<animateTransform
													attributeName="transform"
													type="rotate"
													from="0 67 67"
													to="-360 67 67"
													dur="2.5s"
													repeatCount="indefinite"
												/>
											</path>
											<path d="M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z">
												<animateTransform
													attributeName="transform"
													type="rotate"
													from="0 67 67"
													to="360 67 67"
													dur="8s"
													repeatCount="indefinite"
												/>
											</path>
										</svg>
									) : (
										`Sign Up`
									)}
								</button>

								<span
									onClick={() => setPage('login')}
									className={classes.helpLinks}
								>
									Have an account? <div className={classes.anchor}>Log in</div>
								</span>
							</form>
						</>
					)}

					{page === 'guest' && (
						<>
							<form onSubmit={submitHandlerGuest} className={classes.form}>
								<div className={classes.infoText}>
									Guests cannot use promo codes
								</div>
								<div className={classes.logInWithEmail}>
									<Form.Group controlId="firstName">
										<Form.Control
											className={classes.formControl}
											type="text"
											name="firstName"
											placeholder="name"
											value={guestName}
											onChange={(e) => setGuestName(e.target.value)}
											required
										/>
									</Form.Group>
									<Form.Group controlId="Email">
										<Form.Control
											className={classes.formControl}
											type="email"
											placeholder="email"
											value={guestEmail}
											name="emailGuest"
											onChange={(e) => setGuestEmail(e.target.value)}
											required
										/>
									</Form.Group>
								</div>

								<button className={classes.button} type="submit">
									{loading ? (
										<svg
											width="20px"
											height="20px"
											viewBox="0 0 135 135"
											xmlns="http://www.w3.org/2000/svg"
											fill="#fff"
										>
											<path d="M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z">
												<animateTransform
													attributeName="transform"
													type="rotate"
													from="0 67 67"
													to="-360 67 67"
													dur="2.5s"
													repeatCount="indefinite"
												/>
											</path>
											<path d="M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z">
												<animateTransform
													attributeName="transform"
													type="rotate"
													from="0 67 67"
													to="360 67 67"
													dur="8s"
													repeatCount="indefinite"
												/>
											</path>
										</svg>
									) : (
										`Continue as guest`
									)}
								</button>
							</form>
						</>
					)}
				</div>
			</motion.div>
		</CustomModal>
	);
};

export default AuthUser;
