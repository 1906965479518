"use client";
import Link from "next/link";
import Image from "next/image";

//Styles

import { motion } from "framer-motion";
import { useState, useEffect } from "react";

import useLocalStorage from "@/components/Hooks/UseLocalStorage";
import FavoriteIcon from "@/components/UI/FavoriteIcon";
import classes from "./Listing.module.css";

import moment from "moment";
import { useAuthContext } from "@/context";

const Listing = (props) => {
  const [isHovering, setIsHovering] = useState();

  useEffect(() => {
    setIsHovering(window.innerWidth < 600 ? true : false);
  }, []);

  const { auth } = useAuthContext();

  const { listing, idx } = props;
  const [recentlyViewed, setRecentlyViewed] = useLocalStorage(
    "recentlyViewed",
    []
  );

  // Segment
  const onClickButton = (list) => {
    const updatedList = [];
    const updatedRecentlyViewed = recentlyViewed
      .filter((o) => o._id !== list?._id)
      .slice(-15);
    // Add the new object to the updated array

    updatedList.push(...updatedRecentlyViewed, {
      _id: list._id,
      item_category: list.newSchema.categories[0],
      item_category2: list.newSchema.subCategories[0]
        ? list.newSchema.subCategories[0]
        : "",
      item_category3: list.newSchema.types[0] ? list.newSchema.types[0] : "",
      external_seller: list.author ? list.author : "",
      price: list.price ? list.price : "",
      title: list.title ? list.title : "",
      brand: list.brand
        ? list.brand
        : list.velouAttributes?.manufacturer?.length > 0
        ? list.velouAttributes?.manufacturer[0]
            ?.replace(/_/g, " ")
            ?.split(" ")
            ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            ?.join(" ")
        : list.velouAttributes?.brands?.length > 0
        ? list.velouAttributes?.brands[0]
            ?.replace(/_/g, " ")
            ?.split(" ")
            ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            ?.join(" ")
        : "",
      offerEmail: list.price * 0.9,
      img: list.images[0] ? list.images[0] : "",
    });

    setRecentlyViewed(updatedList);
    process.env.NEXT_PUBLIC_NODE_ENV === "production" &&
      process.env.OG_DB === "true" &&
      global?.window &&
      window.analytics.track("view_item", {
        queryID: localStorage.getItem("queryID")
          ? localStorage.getItem("queryID")
          : "",
        currency: "USD",
        position: idx ? idx + 1 : 0,
        value: (list.price * 0.2).toFixed(0),
        external_seller: list.author ? list.author : "",
        index: "kashew_listings",
        eventType: "click",
        objectID: list._id,
        items: [
          {
            item_id: list._id,
            item_name: list.title,
            currency: "USD",
            item_brand: list.brand
              ? list.brand
              : list.velouAttributes?.manufacturer?.length > 0
              ? list.velouAttributes?.manufacturer[0]
                  ?.replace(/_/g, " ")
                  ?.split(" ")
                  ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  ?.join(" ")
              : list.velouAttributes?.brands?.length > 0
              ? list.velouAttributes?.brands[0]
                  ?.replace(/_/g, " ")
                  ?.split(" ")
                  ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  ?.join(" ")
              : "",
            item_category: list.newSchema.categories[0],
            item_category2: list.newSchema.subCategories[0]
              ? list.newSchema.subCategories[0]
              : "",
            item_category3: list.newSchema.types[0]
              ? list.newSchema.types[0]
              : "",
            price: list.price.toFixed(0),
          },
        ],
      });
    if (!auth.loading && auth?.isAuthenticated) {
      process.env.NEXT_PUBLIC_NODE_ENV === "production" &&
        process.env.OG_DB === "true" &&
        global?.window &&
        window.analytics.identify(auth.user._id, {
          email: auth.user.email,
          recentlyViewedItemsAll: JSON.stringify(updatedList.slice(-2)),
          recentlyViewedItems: JSON.stringify(
            updatedList
              .filter((o) => o.external_seller === "622651a7a84d1d6c836497c3")
              .slice(-2)
          ),
        });
    }
  };

  const correctEncoded = (str) => {
    return str.replace(/%20/g, " ").replace(/â/g, "’");
  };

  const badgeCreator = (listing) => {
    let badgeText = "";
    let badgeClass = "";
    if (global?.window && !window.location.pathname.includes("selections")) {
      if (listing?.freeShipping) {
        badgeText = "Free Shipping";
        badgeClass = classes.newBadge;
        return <div className={badgeClass}>{badgeText}</div>;
      } else if (listing?.iconicDesign) {
        badgeText = "Iconic Design 💎";
        badgeClass = classes.newBadge;
        return <div className={badgeClass}>{badgeText}</div>;
      } else if (listing?.topPicks) {
        badgeText = "Popular 🌟";
        badgeClass = classes.newBadge;
        return <div className={badgeClass}>{badgeText}</div>;
      } else if (moment().diff(moment(listing.date), "days") <= 5) {
        badgeText = "Just in";
        badgeClass = classes.newBadge;
        return <div className={badgeClass}>{badgeText}</div>;
      }
    } else {
      return null;
    }
  };

  const displayTitle = listing.title;

  return (
    <>
      <Link
        href={`/product/${listing?.kashewId}/${listing?.title
          ?.toLowerCase()
          .replace(/[_\W]+/g, "-")
          .replace(/^-+/, "")
          .replace(/-+$/, "")}`}
        // className={classes.Link}
        className={`${classes.listingCard} `}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => onClickButton(listing)}
        key={idx}
      >
        {badgeCreator(listing)}
        <div className={classes.carousel}>
          {listing?.images[0] && listing.images[0] !== "undefined" && (
            <Image
              className={
                listing?.status !== "active"
                  ? classes.soldImg1
                  : classes.listingImg1
              }
              width={160}
              height={160}
              priority={idx && idx < 8 ? true : false}
              loading={idx && idx < 8 ? "eager" : "lazy"}
              src={`${listing?.images[0]}?tr=w-300`}
              alt={displayTitle}
            />
          )}
          {listing?.estRetailPrice &&
            listing?.estRetailPrice > listing?.price && (
              <div className={classes.dealBadge}>
                SALE -
                {`${(
                  ((listing?.price - listing?.estRetailPrice) /
                    listing?.estRetailPrice) *
                  -100
                ).toFixed(0)}`}
                %
              </div>
            )}

          {listing?.status !== "active" && (
            <div className={classes.sold}>Sold</div>
          )}

          <motion.div
            className={classes.favorite}
            initial={isHovering ? { opacity: 1 } : { opacity: 0 }}
            animate={isHovering ? { opacity: 1 } : { opacity: 0 }}
            transition={{
              duration: 0.4,
            }}
          >
            <FavoriteIcon listing={listing} />
          </motion.div>
        </div>
        <div className={classes.cardBody}>
          <h3 className={classes.title}> {correctEncoded(displayTitle)}</h3>
          {listing?.brand ? (
            <h3 className={classes.brand}> {listing.brand?.slice(0, 50)} </h3>
          ) : listing.velouAttributes?.manufacturer?.length > 0 ? (
            <h3 className={classes.brand}>
              {" "}
              {listing.velouAttributes?.manufacturer[0]
                ?.replace(/_/g, " ")
                ?.split(" ")
                ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                ?.join(" ")
                .slice(0, 50)}{" "}
            </h3>
          ) : listing.velouAttributes?.brands?.length > 0 ? (
            <h3 className={classes.brand}>
              {" "}
              {listing.velouAttributes?.brands[0]
                ?.replace(/_/g, " ")
                ?.split(" ")
                ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                ?.join(" ")
                ?.slice(0, 50)}{" "}
            </h3>
          ) : (
            ""
          )}

          <div className={classes.infoSection1}>
            {listing?.estRetailPrice &&
            listing?.estRetailPrice > listing.price ? (
              <span className={`${classes.priceRed}`}>
                ${listing.price.toLocaleString()}
              </span>
            ) : (
              <span className={`${classes.price}`}>
                ${listing.price.toLocaleString()}
              </span>
            )}
            {listing.estRetailPrice &&
              listing.estRetailPrice > listing.price && (
                <span className={`${classes.retailPrice}`}>
                  ${listing.estRetailPrice.toLocaleString()}
                </span>
              )}
            {!listing.priceIsFirm && (
              <div className={classes.negotiable}>Negotiable</div>
            )}
          </div>
        </div>
      </Link>
    </>
  );
};

export default Listing;
