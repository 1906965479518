"use client";
import React, { useEffect, useRef, useState } from "react";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import SearchDiscovery from "./SearchDiscovery";
import classes from "./SearchBar.module.css";
import Image from "next/image";
import { IoClose } from "react-icons/io5";
import TypeOut from "react-typeout";
const algoliasearch = require("algoliasearch");
import { useRouter } from "next/navigation";
const client =
  process.env.NEXT_PUBLIC_OG_DB === "true"
    ? algoliasearch(
        process.env.NEXT_PUBLIC_ALGOLIA_APPID,
        process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
      )
    : algoliasearch(
        process.env.NEXT_PUBLIC_DUMMY_ALGOLIA_APPID,
        process.env.NEXT_PUBLIC_DUMMY_ALGOLIA_API_KEY
      );
const index = client.initIndex("kashew_listings");
const suggestionsIndex = client.initIndex(
  process.env.NEXT_PUBLIC_OG_DB === "false"
    ? "kashew_listings"
    : "kashew_listings_query_suggestions"
); // This should match your configured suggestions index name

const SearchBar = (props) => {
  const { setExpanded, expanded } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [active, setActive] = useState(false);
  const [recentSearches, setRecentSearches] = useLocalStorage("searchTerm", []);
  const [suggestions, setSuggestions] = useState([]);
  const [querySuggestions, setQuerySuggestions] = useState([]);
  const ref = useRef();
  const inputRef = useRef();
  const router = useRouter();

  const onClickButton = () => {
    setActive(false);

    if (!searchTerm) return;

    if (expanded) {
      setExpanded(false);
    }

    const trimmedSearchTerm = searchTerm.trim(); // Trim leading and trailing whitespace

    // Check if the trimmed search term is not empty
    if (trimmedSearchTerm !== "") {
      router.push(`/collections?keyword=${trimmedSearchTerm}`);
      setSearchTerm();

      const maxId =
        recentSearches.length > 0
          ? Math.max(...recentSearches.map((s) => s.id))
          : 0;

      // Increment the maximum id by 1 for the new search term
      const nextId = maxId + 1;

      const newSearchTerm = {
        id: nextId,
        searchTerm: trimmedSearchTerm,
      };

      setRecentSearches([...recentSearches, newSearchTerm]); // Update recent searches

      process.env.NEXT_PUBLIC_NODE_ENV === "production" &&
        process.env.OG_DB === "true" &&
        global?.window &&
        window.analytics.track("search", {
          search_term: trimmedSearchTerm,
        });
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (active && ref.current && !ref.current.contains(e.target)) {
        setActive(!active);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [active]);

  const submitHandler = (e) => {
    e.preventDefault();
    onClickButton();
  };

  const fetchSuggestions = async (inputValue) => {
    if (inputValue.length < 3) {
      setSuggestions([]);
      return;
    }

    if (inputValue.length < 1) {
      setSuggestions([]);
      return;
    }

    try {
      const searchResults = index.search(inputValue, { clickAnalytics: true });
      const suggestionsResults = suggestionsIndex.search(inputValue, {
        hitsPerPage: 10, // Limit the number of results to 10
      });
      const [hitsResponse, suggestionsResponse] = await Promise.all([
        searchResults,
        suggestionsResults,
      ]);

      setSuggestions(hitsResponse.hits);
      setQuerySuggestions(suggestionsResponse.hits);
    } catch (error) {
      console.error("Failed to fetch suggestions", error);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debouncedOnChange = React.useCallback(
    debounce(async (value) => {
      const updatedValue = value.replace("&", "and");
      setSearchTerm(updatedValue);
      await fetchSuggestions(updatedValue);
    }, 0),
    []
  );

  const onChange = (e) => {
    if (e?.target?.value && e?.target?.value) {
      debouncedOnChange(e.target.value);
      inputRef.current.value = e.target?.value;
    } else if (e && e.length > 0) {
      debouncedOnChange(e);
      inputRef.current.value = e;
    } // If the input is empty, clear the suggestions
    else {
      setSuggestions([]);
      setQuerySuggestions([]);
      inputRef.current.value = "";
      setSearchTerm("");
    }
  };

  const deleteHandler = () => {
    inputRef.current.value = "";
    setSearchTerm("");
    setSuggestions([]);
    setQuerySuggestions([]);

    router.push("/collections");
  };

  const searchForSuggestion = [
    "west elm",
    "mid century sideboard",
    "brown leather sofa",
    "vintage dresser",
    "living room chair",
    "sectional sofa",
    "restoration hardware",
    "dining table",
    "dining set",
    "modern coffee table",
    "CB2",
  ];

  const focusHandler = () => {
    setActive(true);
    inputRef?.current?.focus();
  };

  return (
    <span ref={ref} className={props.className}>
      <form onSubmit={submitHandler} className={`${classes.searchBar} `}>
        <input
          aria-label="Search"
          onClick={() => setActive(true)}
          className={classes.input}
          type="text"
          onChange={(e) => onChange(e)}
          ref={inputRef}
        />

        {searchTerm?.length > 0 || inputRef?.current?.value ? (
          <IoClose
            className={classes.closeIcon}
            onClick={() => deleteHandler()}
          />
        ) : (
          <>
            <span
              className={classes.searchPlaceholder}
              onClick={() => focusHandler()}
            >
              Search for
              <TypeOut words={searchForSuggestion} typeSpeed={80} />
            </span>
            <Image
              width={15}
              height={15}
              src="https://ik.imagekit.io/kuj9zqlrx0or/Static/searchIcon.png"
              alt="Search Icon"
              className={classes.lenseIcon}
              onClick={submitHandler}
            />
          </>
        )}
        <button className={classes.displayNone} type="submit" />
      </form>
      {active && !expanded && (
        <SearchDiscovery
          searchTerm={searchTerm}
          active={active}
          setActive={setActive}
          onChange={onChange}
          suggestions={suggestions}
          querySuggestions={querySuggestions}
        />
      )}
    </span>
  );
};

export default SearchBar;
